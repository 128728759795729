<template>
    <sys-register-base
      :loading="loading"
      @onSave="save"
      :model.sync="item"
      :title="model && item.id ? `Atualizar categoria` : `Cadastrar categoria`"
      icon="mdi-sitemap"
      v-bind:show.sync="dialog"
      maxWidth="30%"
    >
        <template v-slot:form>
            <v-row class="mt-1">
                <v-col cols="12" v-if="message">
                    <v-alert
                        outlined
                        color="warning"
                        prominent
                        border="left"
                        >
                        {{ message }}
                        </v-alert>
                </v-col>
                <EmcFormTextField 
                    v-model="item.name" 
                    label="Nome*" 
                    md="12"
                    outlined
                    :error="errors.length > 0"
                    :error-messages="errors.name"
                />
                <EmcFormTextField 
                    v-model="item.icon" 
                    label="Icone" 
                    md="6"
                    outlined
                    :error="errors.length > 0"
                    :error-messages="errors.icon"
                />
                <EmcFormTextField 
                    v-model="item.color" 
                    label="Cor" 
                    md="6"
                    outlined
                    :error="errors.length > 0"
                    :error-messages="errors.color"
                />
            </v-row>
            <v-row>
                <v-col cols="12" md="5">
                    <v-sheet class="px-2">
                        <v-switch
                            v-model="item.is_receipt"
                            inset
                            label="Tipo para receita?"
                            hide-details
                            color="green"
                        ></v-switch>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row>
                <EmcFormDecimalTextField 
                    v-model="item.paid_taxation"
                    append-icon="mdi-percent-outline"
                    label="Imposto"
                    hint="Será utilizado para calcular o imposto que será pago "
                    outlined
                    md="4"
                    :disabled="!item.is_receipt"
                    :error="errors.length > 0"
                    :error-messages="errors.color"
                />
                <EmcFormDecimalTextField 
                    v-model="item.interest_charges"
                    append-icon="mdi-percent-outline"
                    label="Juros"
                    hint="Será utilizado para calcular pagamentos recebidos em atraso"
                    outlined
                    md="4"
                    :disabled="!item.is_receipt"
                    :error="errors.length > 0"
                    :error-messages="errors.color"
                />
                <EmcFormDecimalTextField 
                    v-model="item.late_payment_percent"
                    append-icon="mdi-percent-outline"
                    label="Mora"
                    hint="Será utilizado para calcular pagamentos recebidos em atraso"
                    outlined
                    md="4"
                    :disabled="!item.is_receipt"
                    :error="errors.length > 0"
                    :error-messages="errors.color"
                />
                <EmcFormTextArea 
                    v-model="item.description"
                    label="Descrição"
                    outlined
                    md="12"
                    :error="errors.length > 0"
                    :error-messages="errors.description"
                />
                <v-col cols="12" md="4">
                    <v-sheet class="px-2">
                        <v-switch
                            v-model="item.active"
                            inset
                            :label="item.active ? `Ativo` : `Inativo`"
                            hide-details
                        ></v-switch>
                    </v-sheet>
                </v-col>
            </v-row>
        </template>
    </sys-register-base>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'FormRegisterType',
    data () {
        return {
            loading: false,
            message: null,
            errors: {}
        }
    },
    props: {
        show: { type: Boolean, default: false },
        model: { default: null },
        title: { default: "título" }
    },
    computed: {
        dialog: {
            get() {
                return this.show
            },
            set(value) {
                this.$emit('update:show', value)
            }
        },
        color() {
            return this.model.is_input ? `green lighten-2` : `orange lighten-2`
        },
        item: {
            get() {
                return this.model
            },
            set(value) {
                this.$emit('model', value)
            }
        }
    },
    watch: {
        'item.is_receipt': function(newVal, oldVal) {
            if((newVal != oldVal) && !newVal) {
                this.item.paid_taxation = null
                this.item.interest_charges = null
                this.item.late_payment_percent = null
            }
        }
    },
    methods: {
        ...mapActions('finance', ['ActionFinancialPostingTypesStore', 'ActionFinancialPostingTypesUpdate']),
        initVariables() {
            this.loading = !this.loading
            this.message = null
            this.errors = {}
        },
        save(edit) {
            
            this.initVariables()

            if(edit) {
                this.ActionFinancialPostingTypesUpdate(this.model)
                    .then((res) => {
                        this.message = res.message;
                        this.$emit('onCompleted', res.data);
                    })
                    .catch((error) => {
                        this.errors     = error.errors;
                        this.message    = error.message;
                    })
                    .finally(() => {
                        this.loading = !this.loading
                    })
            } else {
                this.ActionFinancialPostingTypesStore(this.model)
                    .then((res) => {
                        this.message = res.message;
                        this.$emit('onCompleted', res.data);
                    })
                    .catch((error) => {
                        this.errors     = error.errors;
                        this.message    = error.message;
                    })
                    .finally(() => {
                        this.loading = !this.loading
                    })
            }
        }
    }
}
</script>